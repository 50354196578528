.homepage-container {
  margin: 3% 36% 0 4%;
  padding: 10px 30px 30px 30px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.title {
  font-size: 2em;
  margin-bottom: 20px;
}

.top-right {
  position: absolute;
  top: 20px;
  right: 20px;
}

.edit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
}

.edit-button:hover {
  background-color: #0056b3;
}

.profile-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.info-section {
  flex: 1;
  padding-left: 20px;
}

.editable-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.editable-label {
  font-weight: bold;
  margin-right: 10px;
}

.editable-input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.editable-text {
  flex: 1;
  margin-right: 10px;
}

.editable-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}

.editable-button:hover {
  background-color: #0056b3;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.avatar-input {
  padding: 5px;
}
